import { useCallback, useEffect, useRef } from 'react'

import { animateProgress, AnimateProgressOptions } from './animateProgress'

interface Options {
  complete: boolean
  loading: boolean
}

export const useLoadingProgress = (
  { loading, complete }: Options,
  cb: (t: number) => void
) => {
  const idRef = useRef(0)
  const tRef = useRef(0)

  const updateT = useCallback((id: number, t: number) => {
    if (idRef.current !== id) {
      return
    }

    tRef.current = t
    cb(t)
  }, [])

  const requestAnimation = useCallback(
    async (id: number, options: Partial<AnimateProgressOptions>) => {
      if (idRef.current !== id) {
        return
      }

      await animateProgress({ ...options, from: tRef.current }, t =>
        updateT(id, t)
      )
    },
    []
  )

  const loadingRef = useRef(loading)
  const completeRef = useRef(complete)

  const update = (loading: boolean, complete: boolean) => {
    const id = ++idRef.current

    // Two `setState` statements in a row may cause two renders to occur. This is a problem
    // when setting both `loading` and `complete` at the same time because we rely on their
    // previous value to determine the animation behavior.
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(async () => {
      if (idRef.current !== id) {
        return
      }

      const lastLoading = loadingRef.current
      const lastComplete = completeRef.current

      loadingRef.current = loading
      completeRef.current = complete

      if (!lastComplete && loading) {
        // Was idle (non-completed) and started loading. Perform the loading animation.
        await requestAnimation(id, {
          to: 0.2,
          duration: 800,
          bezier: [0.32, 0.09, 0.36, 0.78],
        })
        await requestAnimation(id, {
          to: 0.8,
          duration: 11000,
          bezier: [0.3, 0.59, 0.45, 0.99],
        })
        return
      }

      if (lastLoading && complete) {
        // Was running the loading animation and completed. Quickly transition to 1.
        await requestAnimation(id, { to: 1, duration: 300 })
        return
      }

      // At this point `loading` and `complete` are changing in a way that this hook
      // does not expect.
      //
      // As a fallback, go to 1 if complete, 0.3 if loading and 0 if neither.
      if (complete) {
        await requestAnimation(id, { to: 1, duration: 400 })
      } else if (loading) {
        await requestAnimation(id, { to: 0.3, duration: 400 })
      } else {
        await requestAnimation(id, { to: 0, duration: 400 })
      }
    })
  }

  useEffect(() => {
    update(loading, complete)
  }, [loading, complete])
}
