import BezierEasing from 'bezier-easing'

/**
 * Linear interpolation between two numbers.
 */
const lerp = (a: number, b: number, t: number) => a * (1 - t) + b * t

export interface AnimateProgressOptions {
  from: number
  to: number | (() => number)
  duration: number
  bezier: [number, number, number, number]
}

const defaultOpts: AnimateProgressOptions = {
  from: 0,
  to: 1,
  duration: 500,
  bezier: [0.46, 0.19, 0.13, 0.98],
}

export function animateProgress(
  options: Partial<AnimateProgressOptions>,
  fn: (t: number) => void
): Promise<boolean> & { cancel: () => void } {
  let cancelled = false

  const promise = new Promise<boolean>(resolve => {
    const opts = {
      ...defaultOpts,
      ...options,
    }

    const [x0, y0, x1, y1] = opts.bezier
    const easing = BezierEasing(x0, y0, x1, y1)
    const start = Date.now()

    const tick = () => {
      if (cancelled) {
        resolve(true)
        return
      }

      const linearT = (Date.now() - start) / opts.duration
      let t: number

      if (linearT >= 1) {
        t = 1
      } else {
        t = easing(linearT)
        requestAnimationFrame(tick)
      }

      const { from } = options
      const to = typeof opts.to === 'number' ? opts.to : opts.to()

      fn(lerp(from, to, t))

      if (t === 1) {
        resolve(false)
      }
    }

    tick()
  }) as Promise<boolean> & { cancel: () => void }

  promise.cancel = () => {
    cancelled = true
  }

  return promise
}
