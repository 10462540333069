export const InfoIcon = ({ color = 'black', ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.84 23.84" {...rest}>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={color}
          d="M11.92,10.46a.75.75,0,0,0-.75.75v4.4a.75.75,0,0,0,1.5,0v-4.4A.75.75,0,0,0,11.92,10.46Z"
        />
        <path
          fill={color}
          d="M11.92,0A11.92,11.92,0,1,0,23.84,11.92,11.94,11.94,0,0,0,11.92,0Zm0,21.84a9.92,9.92,0,1,1,9.92-9.92A9.93,9.93,0,0,1,11.92,21.84Z"
        />
        <circle fill={color} cx="11.92" cy="7.82" r="0.84" />
      </g>
    </g>
  </svg>
)
