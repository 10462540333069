import styled from 'styled-components'

import { Type } from '../Typography/Typography'

export const Content = styled.span`
  position: relative;
  display: block;
  z-index: 1;
`

export const ProgressInner = styled.span`
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(-100%);
`

export const Text = styled(Type)`
  transition: none;
  margin-bottom: 2px;
  margin-top: 1px;
`
